export class KeyboardShortcuts_1__1
{
    constructor(data)
	{
        this.app = data.app
	}

	watch()
	{
        key("alt+g", () => {
			window.location = "/"
		})
        key("alt+c", () => {
			window.location = contrastLink
		})
		key("alt+t", () => {
			window.location = textLink
		})
        key("alt+n", () => {
			window.location = "#nawigacja"
		})
        key("alt+s", () => {
			this.app.modal.target = "search"
			this.app.modal.show()
		})
        key("alt+i", () => {
			window.location = "/resources/Instrukcja_Obslugi_BIP.pdf"
		})
		key("alt+z", () => {
            this.app.install.install()
		})
		key("alt+l", () => {
			this.app.lector.speakOne()
		})
		key("alt+m", () => {
			window.location = "/mapa_strony"
		})
	}
}
